const config = {
    app: {
        name: "Jawha"
    },
    components: {
        drawer: {
            width: 240
        }
    },
    auth0: {
        auth0_domain: "jawha.eu.auth0.com",
        auth0_clientId: "wMVw0QAt2ZKBF68hwjnfC4ZzvOewqXZl",
        auth0_audience: "https://jawha.eu.auth0.com/api/v2/",
        auth0_scope: "read:users read:users_app_metadata read:current_user update:current_user_metadata"
    },

    api: {}
}


export default config;