import React from 'react';
import Sidebar, {DrawerHeader} from "../../bar/sidebar/Sidebar";
import {TopBar} from "../../bar/topbar";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {AppPageContext} from "../Context";

export const AppPage = (props) => {
    const [sidebarIsOpen, setSidebarIsOpen] = React.useState(false);

    const contextValue = {
        sidebarIsOpen: sidebarIsOpen
    }

    const handleSideBarStatusChange = () => {
        setSidebarIsOpen(!sidebarIsOpen);
    }

    return (
        <Box sx={{display: 'flex'}}>
            <AppPageContext.Provider value={contextValue}>
                <TopBar appName={props.appName}
                        handleAppBarButtonClick={handleSideBarStatusChange}/>
                <Sidebar handleSideBarStatusChange={handleSideBarStatusChange}>
                    {props.sideBarItems}
                </Sidebar>
            </AppPageContext.Provider>
            <Box component="main" sx={{flexGrow: 1, p: 3}}>
                <DrawerHeader/>
                {props.children}
            </Box>
        </Box>
    );

};

AppPage.defaultProps = {
    sideBarItems: []
}

AppPage.prototype = {
    topbarColor: PropTypes.string,
    children: PropTypes.node.isRequired,
    sideBarItems: PropTypes.elementType,
    appName: PropTypes.string.isRequired
}

export default AppPage;