import React, {useContext} from "react";
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import {styled} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import {Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import {useAuth0} from "@auth0/auth0-react";
import PropTypes from "prop-types";
import {Login, Logout} from "@mui/icons-material";
import {AppPageContext} from "../../pages/Context";


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: 0,
        width: '100%',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const TopBar = (props) => {
    const {logout, isAuthenticated, loginWithRedirect} = useAuth0();
    const {sidebarIsOpen} = useContext(AppPageContext);

    return (
        <AppBar position="fixed" open={sidebarIsOpen} color={props.color}>
            <Toolbar>
                <IconButton
                    color={sidebarIsOpen ? "secondary" : "inherit"}
                    aria-label="open drawer"
                    onClick={props.handleAppBarButtonClick}
                    edge="start"
                    sx={{
                        marginRight: 5
                    }}
                >
                    <MenuIcon/>
                </IconButton>
                <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'flex'}}}>
                    <Typography variant="h4" noWrap component="div">
                        {props.appName}
                    </Typography>
                </Box>
                <Box sx={{flexGrow: 0, display: {xs: 'none', md: 'flex'}}}>
                    {isAuthenticated ?
                        <Tooltip title="Logout">
                            <IconButton sx={{p: 0}}
                                        onClick={() => logout({logoutParams: {returnTo: window.location.origin}})}>
                                <Logout color="secondary"/>
                            </IconButton>
                        </Tooltip> :
                        <Tooltip title="Login">
                            <IconButton sx={{p: 0}}
                                        onClick={() => loginWithRedirect()}>
                                <Login color="secondary"/>
                            </IconButton>
                        </Tooltip>
                    }
                </Box>
            </Toolbar>
        </AppBar>
    );
}

TopBar.defaultProps = {
    color: "primary",
    sidebarIsOpen: false
}

TopBar.prototype = {
    color: PropTypes.any.isRequired,
    sidebarIsOpen: PropTypes.bool.isRequired,
    appName: PropTypes.string.isRequired,
    handleAppBarButtonClick: PropTypes.func.isRequired
}