import {SideBarPage} from "../components/pages/sidebarpage";
import {Grid} from "@mui/material";
import {navigationItems} from "./NavigationItems";
import config from "../config";

export default function Settings() {
    return <SideBarPage appName={config.app.name} navigationItems={navigationItems}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div>
                    <h2>Settings</h2>
                </div>
            </Grid>
        </Grid>
    </SideBarPage>
}