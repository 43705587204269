import React from 'react';
import PropTypes from "prop-types";
import AppPage from "./AppPage";

export const MenuPage = (props) => {
    return (
        <AppPage appName={props.appName} menuItems={props.menuItems}>
            {props.children}
        </AppPage>
    );
};

MenuPage.prototype = {
    children: PropTypes.node.isRequired,
    appName: PropTypes.string.isRequired,
    menuItems: PropTypes.node.isRequired
}

export default MenuPage;