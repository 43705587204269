import React from 'react';
import PropTypes from "prop-types";
import AppPage from "./AppPage";

export const SideBarPage = (props) => {
    return (
        <AppPage appName={props.appName} sideBarItems={props.navigationItems}>
            {props.children}
        </AppPage>
    );
};

SideBarPage.prototype = {
    children: PropTypes.node.isRequired,
    appName: PropTypes.string.isRequired,
    navigationItems: PropTypes.node.isRequired
}

export default SideBarPage;