import {createTheme} from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#1C1C1C',
            light: '#333333',
            dark: '#000000'
        },
        secondary: {
            main: '#CD7F32',
            light: '#556B2F',
            dark: '#8B0000'
        },
        warning: {
            main: '#CD7F32',
            light: '#556B2F',
            dark: '#8B0000'
        },
        success: {
            main: '#008000',
            light: '#32CD32',
            dark: '#006400'
        },
        error: {
            main: '#FF0000',
            light: '#FF6347',
            dark: '#8B0000'
        },

    },
    background: {
        default: '#ffffff',
        paper: '#ffffff'
    }
});