import {List} from "@mui/material";
import NavigateSideBarItem from "../components/bar/sidebar/NavigateSideBarItem";
import {Home, PointOfSale, Settings, Storefront} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import React from "react";

export const navigationItems = <List>
    <NavigateSideBarItem icon={<Home color="secondary"/>} text={"Home"}
                         to="/"/>
    <NavigateSideBarItem icon={<PointOfSale color="secondary"/>} text={"Sales"}
                         to="/sales"/>
    <NavigateSideBarItem icon={<Storefront color="secondary"/>} text={"Store"}
                         to="/store"/>
    <Divider/>
    <NavigateSideBarItem icon={<Settings/>} text="Administration"
                         to="/settings"/>
</List>