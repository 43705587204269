import * as React from "react";
import {SideBarItem} from "./SideBarItem";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";

export const NavigateSideBarItem = (props) => {
    const navigate = useNavigate();
    return <SideBarItem {...props} onClick={() => navigate(props.to)}/>
}

export default NavigateSideBarItem;

NavigateSideBarItem.prototype = {
    to: PropTypes.string.isRequired
}