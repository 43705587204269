import {SideBarPage} from "../components/pages/sidebarpage";
import {navigationItems} from "./NavigationItems";
import {Grid} from "@mui/material";
import config from "../config";

export default function Sales() {
    return (
        <SideBarPage appName={config.app.name} navigationItems={navigationItems}>
            <Grid item xs={12}>
                <h2>Store</h2>
            </Grid>
        </SideBarPage>
    );
}