import {Grid, ListItemIcon, MenuItem} from "@mui/material";
import React from "react";
import {MenuPage} from "../../../components/pages/menupage";
import config from "../../../config";
import {StarRateRounded} from "@mui/icons-material";

export const ClientPageContext = React.createContext(null);

export function ClientHome() {
    const [selectedCategory, setSelectedCategory] = React.useState(null);
    //Get categories from the API, for each category, create a navigation item
    const categories = [
        {name: "T-shirts"}, {name: "Vestes"}, {name: "Divers"}
    ];

    const menuItems = categories.map(category => {
        return <MenuItem key={category.name} onClick={() => setSelectedCategory(category)}>
            <ListItemIcon>
                <StarRateRounded fontSize="small"/>
            </ListItemIcon>
            {category.name}
        </MenuItem>
    });

    const contextValue = {
        selectedCategory: selectedCategory
    }

    return <ClientPageContext.Provider value={contextValue}>
        <MenuPage appName={config.app.name} menuItems={menuItems}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h1>Client Home</h1>
                </Grid>
            </Grid>
        </MenuPage>
    </ClientPageContext.Provider>
}