import {Grid} from "@mui/material";
import React from "react";
import config from "../../../config";
import {navigationItems} from "../../NavigationItems";
import {SideBarPage} from "../../../components/pages/sidebarpage";

export function AdminHome() {

    return <SideBarPage appName={config.app.name} navigationItems={navigationItems}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h1>Admin Home</h1>
            </Grid>
        </Grid>
    </SideBarPage>;
}