import React from 'react';
import {DrawerHeader} from "../../bar/sidebar/Sidebar";
import {TopBar} from "../../bar/topbar";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {Menu} from "@mui/material";
import {AppPageContext} from "../Context";

export const AppPage = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const contextValue = {}

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <AppPageContext.Provider value={contextValue}>
                <TopBar appName={props.appName}
                        handleAppBarButtonClick={handleClick}/>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                >
                    {props.menuItems}
                </Menu>
            </AppPageContext.Provider>
            <Box component="main" sx={{flexGrow: 1, p: 3}}>
                <DrawerHeader/>
                {props.children}
            </Box>
        </Box>
    );
}

AppPage.defaultProps = {
    menuItems: []
}

AppPage.prototype = {
    topbarColor: PropTypes.string,
    children: PropTypes.node.isRequired,
    menuItems: PropTypes.arrayOf(PropTypes.node).isRequired,
    appName: PropTypes.string.isRequired
}

export default AppPage;