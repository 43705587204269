import {ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import * as React from "react";
import {useContext} from "react";
import {AppPageContext} from "../../pages/Context";
import PropTypes from "prop-types";

export const SideBarItem = (props) => {
    const {sidebarIsOpen} = useContext(AppPageContext);

    return <ListItem key={props.text} disablePadding sx={{display: 'block'}}>
        <ListItemButton
            onClick={props.onClick}
            sx={{
                minHeight: 48,
                justifyContent: sidebarIsOpen ? 'initial' : 'center',
                px: 2.5,
            }}
        >
            <ListItemIcon
                sx={{
                    minWidth: 0,
                    mr: sidebarIsOpen ? 3 : 'auto',
                    justifyContent: 'center',
                }}
            >
                {props.icon}
            </ListItemIcon>
            {sidebarIsOpen && <ListItemText primary={props.text}/>}
        </ListItemButton>
    </ListItem>
}

export default SideBarItem;

SideBarItem.prototype = {
    icon: PropTypes.element.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
}