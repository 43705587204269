import React from 'react';
import {theme} from "./Theme"
import {Route, Routes} from "react-router-dom";
import Settings from "./pages/Settings";
import Sales from "./pages/Sales";
import Store from "./pages/Store";
import {AuthenticationGuard} from "./components/auth0/authentication-guard";
import {AdminHome} from "./pages/homes/admin";
import {useAuth0} from "@auth0/auth0-react";
import {ClientHome} from "./pages/homes/client";
import {App as W7kApp} from "./components/App";

function App() {
    const {isAuthenticated} = useAuth0();

    return <W7kApp theme={theme}>
        <Routes>
            {isAuthenticated && <Route path="/" element={<AdminHome/>}/>}
            {!isAuthenticated && <Route path="/" element={<ClientHome/>}/>}
            <Route path="/settings" element={<AuthenticationGuard component={Settings}/>}/>
            <Route path="/sales" element={<AuthenticationGuard component={Sales}/>}/>
            <Route path="/store" element={<AuthenticationGuard component={Store}/>}/>
        </Routes>
    </W7kApp>
}

export default App;
