import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {Auth0ProviderWithNavigate} from "./components/auth0/auth0-provider-with-navigate";
import {BrowserRouter} from "react-router-dom";
import config from "./config";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Auth0ProviderWithNavigate domain={config.auth0.auth0_domain}
                                       clientId={config.auth0.auth0_clientId}
                                       scope={config.auth0.auth0_scope}
                                       audience={config.auth0.auth0_audience}
                                       redirect_uri={window.location.origin}>
                <App/>
            </Auth0ProviderWithNavigate>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
