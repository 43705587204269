import React from 'react';
import {Backdrop, CircularProgress, CssBaseline, ThemeProvider} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";

export function App(props) {
    const {isLoading} = useAuth0();

    const backDrop = <Backdrop
        sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={true}
    >
        <CircularProgress color="inherit"/>
    </Backdrop>

    return <ThemeProvider theme={props.theme}>
        <CssBaseline/>
        {isLoading ? backDrop : props.children}
    </ThemeProvider>
}
