import {Auth0Provider} from "@auth0/auth0-react";
import React from "react";
import PropTypes from "prop-types";

export const Auth0ProviderWithNavigate = (props) => {

    const domain = props.domain
    const clientId = props.clientId
    const scope = props.scope
    const audience = props.audience
    const redirect_uri = props.redirect_uri

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                scope: scope,
                audience: audience,
                redirect_uri: redirect_uri,
            }}
        >
            {props.children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithNavigate;

Auth0ProviderWithNavigate.propTypes = {
    domain: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    scope: PropTypes.string.isRequired,
    audience: PropTypes.string.isRequired,
    redirect_uri: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
}
